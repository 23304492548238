import { Paragraph } from "@boxt/design-system";

import Image from "@Components/Image";
import Slider from "@Components/pages/common/Slider";

import { Container, IconWrapper, ItemContainer, MDWrapper, Slide, SMWrapper } from "./styles";
import type { ButterPropositionBannerFields, PropositionItem } from "./types";

export type Props = {
  fields: ButterPropositionBannerFields;
};

const Item = ({ content }: { content: PropositionItem }) => {
  const { icon, icon_alt, text, text_theme } = content;
  return (
    <>
      <IconWrapper>
        <Image src={icon} alt={icon_alt} width={20} height={20} />
      </IconWrapper>
      <Paragraph size="small" weight="regular" boxtTheme={text_theme?.name || "slate"}>
        {text}
      </Paragraph>
    </>
  );
};

const ButterPropositionBanner = ({ fields }: Props) => {
  const { bg, proposition_item, id, animation_delay } = fields;

  return (
    <Container $bg={bg.name} id={id}>
      <SMWrapper>
        <Slider
          duration={typeof animation_delay === "string" ? 6000 : animation_delay}
          includeSpacing={false}
          showDotsController={false}
          sliderOptions={{
            loop: true,
          }}
        >
          {proposition_item.map((item) => (
            <Slide className={`keen-slider__slide`} key={item.text}>
              <Item content={item} />
            </Slide>
          ))}
        </Slider>
      </SMWrapper>
      <MDWrapper>
        {proposition_item.map((item) => (
          <ItemContainer key={item.text}>
            <Item content={item} />
          </ItemContainer>
        ))}
      </MDWrapper>
    </Container>
  );
};

export default ButterPropositionBanner;
