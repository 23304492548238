"use client";

import styled from "styled-components";
import tinyColor from "tinycolor2";
import { breakpoint, colors, includeSpacing, rem } from "@boxt/design-system";

export const Container = styled.div`
  width: 100vw;
  height: 100%;
  position: relative;
  overflow: hidden;
  color: ${colors.slate.normal};
  background-color: ${tinyColor(colors.slate.lighter).setAlpha(0.05).toRgbString()};
  margin-left: calc(50% - 50vw);
  margin-right: calc(50% - 50vw);
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  border-radius: ${rem(12)};
  background-color: ${colors.white};
  padding: 0 ${rem(16)} 0 ${rem(16)};
  ${breakpoint("md")} {
    flex-direction: row;
    min-width: ${rem(700)};
    min-height: ${rem(100)};
    padding: 0 ${rem(32)} 0 ${rem(32)};
  }
  ${breakpoint("lg")} {
    min-width: ${rem(800)};
  }
  ${includeSpacing({ mb: 8, mt: 4, mh: 2 })};
`;

export const BackGroundColor = styled.div`
  height: 100%;
  width: 100%;
  overflow: hidden;
  background-color: ${colors.slate.lightest};
`;

export const Text = styled.div`
  display: flex;
  flex-direction: column;
  max-width: ${rem(500)};
  ${includeSpacing({ mv: { sm: 2, md: 0 } })};
`;
