import Trans from "next-translate/Trans";
import { H4, Paragraph } from "@boxt/design-system";

const common = {
  s: <strong />,
};

const SingleRow = ({ nameSpace: { path, key } }: { nameSpace: { path: string; key: string } }) => {
  return (
    <>
      <Trans
        i18nKey={`${path}:errors.${key}.title`}
        components={{
          h: <H4 align="center" />,
          ...common,
        }}
      />
      <Trans
        i18nKey={`${path}:errors.${key}.text`}
        components={{
          p: <Paragraph top={3} bottom={7} boxtTheme="slate" align="center" />,
          ...common,
        }}
      />
    </>
  );
};

const DoubleRow = ({ nameSpace: { path, key } }: { nameSpace: { path: string; key: string } }) => {
  return (
    <>
      <Trans
        i18nKey={`${path}:errors.${key}.title`}
        components={{
          h: <H4 align="center" />,
          ...common,
        }}
      />
      <Trans
        i18nKey={`${path}:errors.${key}.body.upper`}
        components={{
          p: <Paragraph top={6} bottom={3} boxtTheme="slate" align="center" />,
          ...common,
        }}
      />
      <Trans
        i18nKey={`${path}:errors.${key}.body.lower`}
        components={{
          p: <Paragraph bottom={7} boxtTheme="slate" align="center" />,
          ...common,
        }}
      />
    </>
  );
};

export type Template = "single" | "double";
export type Props = {
  template: Template;
  nameSpace: { path: string; key: string };
};

const ContentTemplate = ({ template, nameSpace }: Props) => {
  switch (template) {
    case "double":
      return <DoubleRow nameSpace={nameSpace} />;
    default:
      return <SingleRow nameSpace={nameSpace} />;
  }
};

export default ContentTemplate;
