import { useRouter } from "next/compat/router";
import useTranslation from "next-translate/useTranslation";
import { Button, H5, Paragraph, Spacing } from "@boxt/design-system";

import { ReactComponent as Error } from "@Images/icons/calendar-error.svg";

import { Container, Content } from "./styles";

const Calendar = () => {
  const { t } = useTranslation("common");
  const router = useRouter();
  return (
    <Spacing mt={3} mh={{ sm: 2, md: 0 }}>
      <Container>
        <Content mt={{ sm: 2, md: 4 }} mb={{ sm: 3, md: 5 }} mh={2}>
          <Error />
          <H5 top={2}> {t("errors.invalid-date.title")} </H5>
          <Paragraph top={2} align="center">
            {t("errors.invalid-date.note")}
          </Paragraph>
          <Spacing mb={{ sm: 3, md: 4, lg: 5 }} mt={2}>
            <Button
              onClick={() => {
                router?.reload();
              }}
              boxtTheme="jade"
              isFullWidth
            >
              {t("errors.invalid-date.button")}
            </Button>
          </Spacing>
        </Content>
      </Container>
    </Spacing>
  );
};

export default Calendar;
