import { useCallback, useEffect, useRef } from "react";
import type { Id } from "react-toastify";
import { toast, ToastContainer } from "react-toastify";
import Trans from "next-translate/Trans";
import useTranslation from "next-translate/useTranslation";
import { H4, Icon, Paragraph, Spacing } from "@boxt/design-system";

import currencyFormatter from "@Helpers/currency";
import type { PromoCode } from "@Collections/types/promoCode";

import { CodeContainer, ContentContainer, CrossWrapper, FlexWrapper } from "./styles";

import "react-toastify/dist/ReactToastify.css";

type ContentProps = {
  onClose: () => void;
  promoCode: PromoCode;
};

export const Content = ({ onClose, promoCode }: ContentProps) => {
  const { t } = useTranslation("common");
  const { type, name } = promoCode.promoCode;

  const title =
    type === "BoxtEngine::Shopping::PromoCodes::FixedAmount"
      ? t("promo-code-toast.fixed.title", { amount: currencyFormatter(promoCode.promoCode.value) })
      : t("promo-code-toast.free-package.title");
  const body =
    type === "BoxtEngine::Shopping::PromoCodes::FixedAmount"
      ? t("promo-code-toast.fixed.body", { amount: currencyFormatter(promoCode.promoCode.value) })
      : t("promo-code-toast.free-package.body", { packageName: promoCode.promoCode.name });

  return (
    <ContentContainer>
      <CrossWrapper onClick={onClose}>
        <Icon name="cross" size="small" />
      </CrossWrapper>
      <Spacing mt={3} />
      <H4 align="center" bottom={3}>
        🎉 {title}
      </H4>
      <Paragraph align="center" bottom={5} boxtTheme="slate" weight="regular">
        {body}
      </Paragraph>
      <CodeContainer>
        <Spacing mh={1}>
          <Icon name="discount" size="small" boxtTheme="jade" />
        </Spacing>
        <FlexWrapper>
          <Trans
            i18nKey={"common:promo-code-toast.applied"}
            components={{
              p: <Paragraph align="left" boxtTheme="jade" weight="regular" />,
              s: <strong />,
            }}
            values={{ name: name.toUpperCase() }}
          />
        </FlexWrapper>
      </CodeContainer>
    </ContentContainer>
  );
};

type Props = {
  promoCode?: PromoCode;
};

const PromoCodeToast = ({ promoCode }: Props) => {
  const toastId = useRef<Id>();

  const handleClose = useCallback(() => {
    toast.dismiss(toastId.current);
  }, [toastId]);

  useEffect(() => {
    if (promoCode) {
      const id = toast(<Content onClose={handleClose} promoCode={promoCode} />, {
        containerId: "promotion-toast",
        position: "top-right",
        autoClose: false,
        closeOnClick: false,
        pauseOnHover: false,
        draggable: false,
        hideProgressBar: true,
        closeButton: false,
      });
      toastId.current = id;
    }
  }, [handleClose, promoCode]);

  return <ToastContainer limit={1} containerId="promotion-toast" />;
};
export default PromoCodeToast;
