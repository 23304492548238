import { useRouter } from "next/router";
import useSWRImmutable from "swr/immutable";

import type { Products as ProductsType } from "@Collections/products";
import { Products } from "@Collections/products";
import { fetcher } from "@DataAccess/local";

const usePromoCode = (promoCodeCookie: string | null = null, productType: ProductsType = Products.Boiler) => {
  const { query, push, pathname } = useRouter();

  const promoCode = promoCodeCookie ? promoCodeCookie : (query.promo as string);

  const { data: promoCodeData, error: promoCodeError } = useSWRImmutable(
    promoCode ? `/customers/promo-codes/${promoCode}` : null,
    fetcher,
    {
      shouldRetryOnError: false,
    },
  );

  const removePromoCodeQueryParam = () => {
    delete query.promo;

    push({ pathname, query }, undefined, { shallow: true });
  };

  const getPromoCodeError = () => {
    if (promoCodeError) {
      return promoCodeError.response.data.error;
    }

    if (promoCodeData && promoCodeData?.error !== null) {
      return promoCodeData?.error;
    }

    return null;
  };

  const isProductTypeValid = promoCodeData?.promoCode?.productCategories?.some(
    (category) => category.value === productType,
  );

  return {
    hasActivePromoCode:
      Boolean(promoCodeData) && promoCodeData?.error === null && !promoCodeError && isProductTypeValid,
    promoCodeData: promoCodeData?.error === null ? promoCodeData : null,
    promoCodeError: getPromoCodeError(),
    removePromoCodeQueryParam,
  };
};

export default usePromoCode;
