import { BOXT_CUSTOMER_URLS, EV_CHARGER_URLS, ORDER_URLS, SOLAR_URLS } from "@Collections/routes";

import AC from "./assets/air-conditioning.png";
import Boilers from "./assets/boilers.png";
import EV from "./assets/ev-charging.png";
import HeatPumps from "./assets/heat-pumps.png";
import SolarBattery from "./assets/solar.png";
import type { Card } from "./types";

export const heroProductWidgetContent: Card[] = [
  {
    image: Boilers,
    title: "Boilers",
    href: BOXT_CUSTOMER_URLS.BOILER,
  },
  {
    image: HeatPumps,
    title: "Heat pumps",
    href: ORDER_URLS.heat_pump.LANDING,
  },
  {
    image: SolarBattery,
    title: "Solar & battery",
    href: SOLAR_URLS.LANDING,
  },
  {
    image: AC,
    title: "Air conditioning",
    href: ORDER_URLS.air_con.LANDING,
  },
  {
    image: EV,
    title: "EV charging",
    href: EV_CHARGER_URLS.LANDING,
  },
];
