import { useEffect } from "react";
import { useRouter } from "next/router";
import { destroyCookie, setCookie } from "nookies";

export const PLP_FILTER_COOKIE_KEY = "plp-filter-personalisation";

export enum PlpFilterCookieValue {
  WorcesterBosch = "worcester-bosch-boiler-filter-active",
  Vaillant = "vaillant-boiler-filter-active",
  LowestPrice = "lowest-price-sort-active",
  HighestPrice = "highest-price-sort-active",
}

export enum BoilerLandingPage {
  CheapBoilers = "/boilers/cheap-boilers",
  WorcesterBosch = "/boilers/worcester-bosch-boilers",
  Vaillant = "/boilers/vaillant-boilers",
}

export const useSetFilterCookie = () => {
  const { asPath } = useRouter();

  useEffect(() => {
    const handleSetFilterCookie = () => {
      switch (asPath) {
        case BoilerLandingPage.CheapBoilers:
          setCookie(null, PLP_FILTER_COOKIE_KEY, PlpFilterCookieValue.LowestPrice, {
            sameSite: true,
            path: "/",
          });
          break;
        case BoilerLandingPage.WorcesterBosch:
          setCookie(null, PLP_FILTER_COOKIE_KEY, PlpFilterCookieValue.WorcesterBosch, {
            sameSite: true,
            path: "/",
          });
          break;
        case BoilerLandingPage.Vaillant:
          setCookie(null, PLP_FILTER_COOKIE_KEY, PlpFilterCookieValue.Vaillant, {
            sameSite: true,
            path: "/",
          });
          break;
        default:
          destroyCookie(null, PLP_FILTER_COOKIE_KEY);
          break;
      }
    };

    handleSetFilterCookie();
  }, [asPath]);
};
