import Image from "next/image";
import useTranslation from "next-translate/useTranslation";
import { Paragraph } from "@boxt/design-system";

import { CardContainer, ImageWrapper, LinkWrapper } from "./styles";
import type { Card } from "./types";

export type Props = {
  card: Card;
  onClick: () => void;
  i18nNamespace: string;
};

export const ProductCard = ({ card, onClick, i18nNamespace }: Props) => {
  const { t } = useTranslation(i18nNamespace);
  const { image, href, title } = card;

  return (
    <LinkWrapper onClick={onClick} href={href} tabIndex={1} aria-label={t("hero-product-widget.label", { title })}>
      <CardContainer>
        <ImageWrapper>
          <Image src={image} alt={t("hero-product-widget.alt", { title })} width={88} />
        </ImageWrapper>
        <Paragraph align="center" boxtTheme="slateDark">
          {title}
        </Paragraph>
      </CardContainer>
    </LinkWrapper>
  );
};
